import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  ADD_NEW_RESUME_FIELD,
  REMOVE_RESUME_FIELD,
  SET_CAROUSEL_PROPERTIES,
  SET_RESUME_PROPERTIES,
  UPDATE_RESUME_FIELD,
  UPDATE_RESUME_FIELD_ITEM,
  UPDATE_RESUME_FOR_EDITOR,
} from "../../../../../redux/reducerSlice/arSlice";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { REMOVE_CAROUSEL } from "../../../../../redux/reducerSlice/arSlice";
// import { AiOutlineDelete } from "react-icons/ai";

import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { loadTexture } from "../EditorUtils";
import imageCompression from "browser-image-compression";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { filter } from "@chakra-ui/system";
import {
  ADD_ITEM_TO_CAROUSEL,
  SET_CAROUSEL_CHILDREN_POSITION,
} from "../../../../../redux/reducerSlice/arSlice";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  TextField,
  Button,
  Typography,
} from "@mui/material";
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DeleteIcon from '@mui/icons-material/Delete';
// import AddIcon from '@mui/icons-material/Add';
import { IoMdAdd } from "react-icons/io";
import { MdOutlineDeleteOutline } from "react-icons/md";

import { MdOutlineExpandCircleDown } from "react-icons/md";
import AddProductIcons from "../modal/AddProductModalIcon";
import { ConvolutionShader } from "three/examples/jsm/Addons";

function ResumeEditorSection({ whatsClicked, open, setOpenModal }) {

  const [expanded, setExpanded] = useState(false);
  const dispatch = useDispatch();
  const arData = useSelector((state) => state.ar.data);
  

  const resumeData = arData.resume?.find(
    (element) => element?.id === whatsClicked?.userData?.id
  );

  const [fields, setFields] = useState([]);
  const [IconIndex, setIconIndex] = useState(1);

  const selectedPhoto = arData?.resume?.find(
    (data) => data?.id === whatsClicked?.userData?.id
  );
  const { id, animation, linkedAnchor } = selectedPhoto || {};
  const { delay, duration, direction } = animation || {};

  const [animationDelay, setAnimationDelay] = useState(delay || 0);
  const [animationDuration, setAnimationDuration] = useState(duration || 0);

  const anchor = useSelector((state) => state.ar.data.anchor) || null;

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setAnimationDelay(delay || 0);
    setAnimationDuration(duration || 0);
  }, [id, delay, duration]);

  useEffect(() => {
    setFields(resumeData?.resumeData || []);
  }, [whatsClicked]);

  const isAnimationCarousel = useSelector((state) => {
    const Resume = state?.ar?.data?.resume?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );

    return Resume?.isAnimation;
  });
  console.log(isAnimationCarousel)

  const handleDelete = (index) => {
    const newFields = fields.filter((_, i) => i !== index);
    setFields(newFields);

    dispatch(REMOVE_RESUME_FIELD({ id: resumeData?.id, fieldId: index + 1 }));
  };

  const handleChange = (event, index) => {
    const { name, value } = event.target;
    console.log(name, value);
    const newFields = fields.map((field, i) =>
      i === index ? { ...field, [name]: value } : field
    );
    setFields(newFields);
  };

  const handleOnBlur = (index) => {
    console.log(fields, "this is feilds");
    const newFields = [...fields];
    console.log(newFields[index], "this is new fields");
    dispatch(
      UPDATE_RESUME_FIELD_ITEM({
        resumeId: resumeData?.id,
        id: index + 1,
        itemData: newFields[index],
      })
    );
  };

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleAddMore = () => {
    setFields((prevFields) => {
      console.log(prevFields, "this is prev fields");
      const updatedFields = [...prevFields, { title: "", description: "" }];
      const fieldData = { title: "", description: "" };
      // Dispatch the action to Redux with the updated fields
      dispatch(
        ADD_NEW_RESUME_FIELD({
          fieldData,
          id: fields.length + 1,
          resumeId: resumeData?.id,
        })
      );
      return updatedFields;
    });
  };

  const handleAnchorChange = (event) => {
    dispatch(UPDATE_RESUME_FOR_EDITOR({ LinkTo: event.target.value, id }));
};

  return (
    <div>
      {whatsClicked?.userData?.type === "resume" && (
        <>
          <div className="mt-2 items-center px-2">
            <div className="mx-auto w-full max-w-lg rounded-lg bg-white px-4 py-2 shadow-md">
            
              <p className="p-2">Resume Feilds</p>
              {fields &&
                fields.map((field, index) => (
                  <Accordion
                    key={index}
                    expanded={expanded === `panel${index}`}
                    onChange={handleAccordionChange(`panel${index}`)}
                    sx={{
                      boxShadow:
                        "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<MdOutlineExpandCircleDown />}
                      aria-controls={`panel${index}-content`}
                      id={`panel${index}-header`}
                    >
                      <Typography
                        className="flex w-full items-center justify-between"
                        sx={{ fontSize: "14px" }}
                      >
                        Field Set {index + 1}
                        <IconButton
                          onClick={() => handleDelete(index)}
                          sx={{ fontSize: "15px", color: "#e94646" }}
                        >
                          <MdOutlineDeleteOutline />
                        </IconButton>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="flex flex-col space-y-2">
                      <div className="flex flex-col space-y-2">
                        <label
                          htmlFor={`input1-${index}`}
                          className="  text-gray-700"
                          style={{ fontSize: "13px" }}
                        >
                          Enter Title Text
                        </label>
                        <input
                          id={`input1-${index}`}
                          type="text"
                          className="h-10 rounded-md border px-3 outline-none focus:ring-2 focus:ring-indigo-500"
                          placeholder="Ex: About Me...."
                          style={{ fontSize: "12px" }}
                          name="title"
                          value={field?.title || ""}
                          onChange={(e) => handleChange(e, index)}
                          onBlur={() => handleOnBlur(index)}
                        />
                      </div>
                      <div className="flex flex-col space-y-2">
                        <label
                          htmlFor={`input2-${index}`}
                          className="  text-gray-700"
                          style={{ fontSize: "13px" }}
                        >
                          Enter description text
                        </label>
                        <textarea
                          id={`input2-${index}`}
                          style={{ fontSize: "12px" }}
                          type="text"
                          className=" rounded-md border px-3 outline-none focus:ring-2 focus:ring-indigo-500"
                          placeholder="Ex: About Me description..."
                          name="description"
                          value={field?.description || ""}
                          onChange={(e) => handleChange(e, index)}
                          onBlur={() => handleOnBlur(index)}
                          rows='6'
                          cols='6'
                        />
                      </div>
                      {field?.icon && field?.icon.src && (
                        <div className="flex items-center">
                          <img
                            src={field.icon.src}
                            alt={field.icon.name}
                            className="mr-2 h-10 w-10 object-contain" 
                          />
                        
                        </div>
                      )}
                      <button
                        onClick={() => {
                          setIconIndex(index);
                          setOpenModal(true);
                        }}
                        type="button"
                        id={`icon-${index}`}
                        className="mt-2 w-full rounded-md bg-brand-main px-4 py-1 font-semibold text-white shadow  focus:outline-none focus:ring-2 "
                      >
                        Set Icon
                      </button>
                    </AccordionDetails>
                  </Accordion>
                ))}
              <div className="mt-4 flex justify-end">
                <button
              
                 
                  className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white"
                  onClick={handleAddMore}
                >
                  Add More
                </button>
              </div>
            </div>
          </div>
          
          <div/>
          {whatsClicked?.userData?.type === "resume" && anchor && (
          <div className="mt-2 px-2">
            <div className="bg-white py-2 px-4 rounded-md shadow-md">
              <p className="py-1">Attach </p>

              {/* Photo Group Edit Bar  */}
              {whatsClicked?.userData?.type === "resume" && (
                <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">

                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Parent Group</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={linkedAnchor || ""}
                      onChange={handleAnchorChange}
                    >
                      <option value="">None</option>
                      {anchor?.map((anchor) => (
                      <option key={anchor.id} value={anchor.id}>
                        {anchor.name }
                      </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          )}
          <div/>

          <div className="mt-2 px-2">
            <div className="rounded-md bg-white px-4 py-2 shadow-md">
              <p className="py-1">Animation</p>

             
            {whatsClicked?.userData?.type === "resume" && (
                <div className="  border-t-2  text-sm ">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isAnimationCarousel}
                        onChange={() => {
                          const action = {
                            key: "isAnimation",
                            value: !isAnimationCarousel,
                            id: resumeData.id,
                          };

                          dispatch(SET_RESUME_PROPERTIES(action));
                        }}
                      />
                    }
                    label={<span style={{ fontSize: 14 }}>Apply</span>}
                  />
                </div>
              )} 

            {whatsClicked?.userData?.type === "resume" &&
              isAnimationCarousel && (
                <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Trigger</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={
                        arData?.resume?.find(
                          (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation.trigger || ""
                      }
                      onChange={(event) => {
                        const action = {
                          parentKey: "Animation",
                          key: "trigger",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_RESUME_PROPERTIES(action));
                      }}
                    >
                      <option value="Autoplay">Autoplay</option>
                      <option value="Click">Click</option>
                    </select>
                  </div>
                  <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Duration (ms)</label>
                    <input
                      id="duration"
                      type="number"
                      className="w-full border p-1"
                      value={animationDuration}
                      onChange={(event) =>                      
                        {
                          setAnimationDuration(event.target.valueAsNumber)
                          const action = {
                            parentKey: "Animation",
                            key: "duration",
                            value: event.target.valueAsNumber,
                            id: whatsClicked?.userData?.id,
                          };
                          dispatch(SET_RESUME_PROPERTIES(action));
                        }
                      }
                      // onBlur={() => {
                      //   const action = {
                      //     parentKey: "Animation",
                      //     key: "duration",
                      //     value: animationDuration,
                      //     id: whatsClicked?.userData?.id,
                      //   };
                      //   dispatch(SET_RESUME_PROPERTIES(action));
                      // }}
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Delay (ms)</label>
                    <input
                      id="delay"
                      type="number"
                      className="w-full border p-1"
                      value={animationDelay}
                      onChange={(event) =>                       
                        {
                          setAnimationDelay(event.target.valueAsNumber)
                          const action = {
                            parentKey: "Animation",
                            key: "delay",
                            value: event.target.valueAsNumber,
                            id: whatsClicked?.userData?.id,
                          };
                          dispatch(SET_RESUME_PROPERTIES(action));
                        }
                      }
                      // onBlur={() => {
                      //   const action = {
                      //     parentKey: "Animation",
                      //     key: "delay",
                      //     value: animationDelay,
                      //     id: whatsClicked?.userData?.id,
                      //   };
                      //   dispatch(SET_RESUME_PROPERTIES(action));
                      // }}
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Direction</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={
                        arData?.resume?.find(
                          (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation.direction || ""
                      }
                      onChange={(event) => {
                        const action = {
                          parentKey: "Animation",
                          key: "direction",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_RESUME_PROPERTIES(action));
                      }}
                    >
                      <option value="topToBottom">Top to Bottom</option>
                      <option value="leftToRight">Left to Right</option>
                      <option value="rightToLeft">Right to Left</option>
                      <option value="bottomToTop">Bottom to Top</option>
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="font-bold">Uploading Image</h1>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>

      <AddProductIcons
        open={open}
        IconIndex={IconIndex}
        resumeId={resumeData?.id}
        setFields={setFields}
        fields={fields}
        handleClose={() => {
          setOpenModal(false);
        }}
      />
    </div>
  );
}

export default ResumeEditorSection;
