import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  SET_DOCUMENT_LINK,
  SET_DOCUMENT_PROPERTIES,
} from "../../../../../redux/reducerSlice/arSlice";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function DocumentEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);

  //select group record
    const anchor = useSelector((state) => state.ar.data.anchor)|| null;
    
    const selectedDocument = ardata?.documents?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );
    const { id, linkedAnchor  } = selectedDocument || {};


  const [animationDelay, setAnimationDelay] = useState(
    ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.delay
  );

  const [animationDuration, setAnimationDuration] = useState(
    ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.duration
  );

  useEffect(() => {
    setAnimationDelay(
      ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.delay
    );

    setAnimationDuration(
      ardata?.documents?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.duration
    );
  }, [whatsClicked?.userData?.id]);

  const documentsDataId = {
    id: ardata?.documents?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    ),
  };

  const isAnimationdocuments = useSelector((state) => {
    const icon = state?.ar?.data?.documents?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );

    return icon?.isAnimation;
  });

  const linkType = useSelector((state) => {
    const icon = state?.ar?.data?.documents?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );

    return icon?.linkType;
  });

  const iconLink = useSelector((state) => {
    const icon = state?.ar?.data?.documents?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return icon?.iconLink;
  });

  const handleAnchorChange = (event) => {
          dispatch(SET_DOCUMENT_PROPERTIES({ key: "LinkTo", value: event.target.value, id }));
      };

  return (
    <div>
      {whatsClicked?.userData?.type === "documents" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <div className="border-t-1 flex w-full items-center justify-center">
              <div className="flex w-full flex-row items-center justify-between">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={linkType === "RedirectLink"}
                      onChange={(event) => {
                        const isChecked = event.target.checked;
                        const actionType = {
                          key: "linkType",
                          value: isChecked ? "RedirectLink" : "",
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_DOCUMENT_PROPERTIES(actionType));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 12 }}>Link</span>}
                  className="w-full"
                />
              </div>
            </div>

            {whatsClicked?.userData?.type === "documents" &&
              linkType === "RedirectLink" && (
                <div className="text-bold mb-2 mt-2 flex w-full flex-col items-center text-xs text-gray-900">
                  <div className="flex w-full gap-3 rounded-lg bg-white px-2">
                    <h1 className="flex items-center py-1">URL:</h1>
                    <input
                      type="text"
                      className="w-full border p-1 outline-none"
                      value={iconLink}
                      onChange={(event) => {
                        const action = {
                          iconLink: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_DOCUMENT_LINK(action));
                      }}
                    />
                  </div>
                </div>
              )}
          </div>
        </div>
      )}

      {whatsClicked?.userData?.type === "documents" && anchor && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
              <p className="py-1">Attach </p>

              {/* icons Group Edit Bar  */}
              {whatsClicked?.userData?.type === "documents" && (
              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Parent Group</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={linkedAnchor || ""}
                      onChange={handleAnchorChange}
                    >
                      <option value="">None</option>
                      {anchor?.map((anchor) => (
                      <option key={anchor.id} value={anchor.id}>
                        {anchor.name }
                      </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          
        )}

      {whatsClicked?.userData?.type === "documents" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p className="py-1">Animation</p>

            {/* Icon Animation checkbox */}
            {whatsClicked?.userData?.type === "documents" && (
              <div className="border-t-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnimationdocuments}
                      onChange={() => {
                        const action = {
                          key: "isAnimation",
                          value: !isAnimationdocuments,
                          id: documentsDataId.id.id,
                        };
                        dispatch(SET_DOCUMENT_PROPERTIES(action));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Apply</span>}
                />
              </div>
            )}

            {/* Icon Animation  */}
            {whatsClicked?.userData?.type === "documents" &&
              isAnimationdocuments && (
                <div className=" my-1 flex flex-col gap-4 bg-white text-sm">
                  <div className="flex flex-col justify-center">
                      <label className="text-gray-900">Trigger</label>
                      <select
                        name=""
                        id=""
                        className="w-full border p-1"
                        value={
                          ardata?.documents?.find(
                            (data) => data?.id === whatsClicked?.userData?.id
                          )?.animation.trigger || ""
                        }
                        onChange={(event) => {
                          const action = {
                            parentKey: "Animation",
                            key: "trigger",
                            value: event.target.value,
                            id: whatsClicked?.userData?.id,
                          };
                          dispatch(SET_DOCUMENT_PROPERTIES(action));
                        }}
                      >
                        <option value="Autoplay">Autoplay</option>
                        <option value="Click">Click</option>
                      </select>
                    </div>
                  <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Duration (ms)</label>
                    <input
                      id="duration"
                      type="number"
                      className="w-full border p-1"
                      value={animationDuration}
                      onChange={(event) =>
                        setAnimationDuration(event.target.valueAsNumber)
                      }
                      onBlur={() => {
                        const action = {
                          parentKey: "Animation",
                          key: "duration",
                          value: animationDuration,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_DOCUMENT_PROPERTIES(action));
                      }}
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Delay (ms)</label>
                    <input
                      id="delay"
                      type="number"
                      className="w-full border p-1"
                      value={animationDelay}
                      onChange={(event) =>
                        setAnimationDelay(event.target.valueAsNumber)
                      }
                      onBlur={() => {
                        const action = {
                          parentKey: "Animation",
                          key: "delay",
                          value: animationDelay,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_DOCUMENT_PROPERTIES(action));
                      }}
                    />
                  </div>

                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Direction</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={
                        ardata?.documents?.find(
                          (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation.direction || ""
                      }
                      onChange={(event) => {
                        const action = {
                          parentKey: "Animation",
                          key: "direction",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_DOCUMENT_PROPERTIES(action));
                      }}
                    >
                      <option value="topToBottom">Top to Bottom</option>
                      <option value="leftToRight">Left to Right</option>
                      <option value="rightToLeft">Right to Left</option>
                      <option value="bottomToTop">Bottom to Top</option>
                    </select>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
}

export default DocumentEditorSection;
