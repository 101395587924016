import { instance } from "common/Instance";
import moment from "moment";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaArrowLeft, FaCalendarAlt, FaChevronDown } from "react-icons/fa";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import PaymentSuccess from "./PaymentSuccess";

const PaymentForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isPaid, setIsPaid] = useState(false);
  const [formData, setFormData] = useState({
    date: moment(new Date()).format("DD/MM/YYYY"),
    amount: "",
    paymentMode: "",

    description: "",
  });

  const userData = useSelector((state) => state?.auth?.auth?.user);

  const userToken = useSelector((state) => state.auth.auth.token);
  const navigate = useNavigate();

  const [invoiceData, setInvoiceData] = useState("");

  const [searchParams, setSearchParams] = useSearchParams();

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validate = () => {
    let tempErrors = {};
    if (!formData.date) tempErrors.date = "Date is required";
    if (!formData.amount) tempErrors.amount = "Amount is required";
    else if (parseFloat(formData.amount) <= 0)
      tempErrors.amount = "Amount must be greater than 0";
    if (!formData.paymentMode)
      tempErrors.paymentMode = "Payment mode is required";
    setErrors(tempErrors);

    console.log(tempErrors, "thisis temp error s");
    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      console.log(formData);
      // Handle form submission

      try {
        setIsLoading(true);
        const response = await instance.post(
          `api/paymentRecord/create_payment`,
          {
            userId: userData?._id,
            client: invoiceData?.userDetails?._id,
            invoice: invoiceData?._id,
            currency: "INR",
            description: formData.description,
            amount: Number(formData.amount),
            paymentMode: formData.paymentMode,
            fullName: invoiceData?.userDetails?.fullName,
            email: invoiceData?.userDetails?.email,
          },
          {
            headers: {
              authorization: `Bearer ${userToken}`,
              // "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.status === 201) {
          toast.success("Payment successfull");
          setIsPaid(true);
          setFormData((prev) => {
            return { ...prev, amount: "" };
          });

          getInvoiceDetails();
        } else {
          toast.error("something went wrong");
        }

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);

        toast.error(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  const getInvoiceDetails = async () => {
    try {
      const response = await instance.get(
        `/api/invoices/get_invoice/${searchParams.get("id")}`,
        {
          headers: {
            authorization: `Bearer ${userToken}`,
          },
        }
      );
      console.log(response, "this is user of the user");
      if (response.status === 200) {
        console.log(response?.data, "this is response ");

        // const { createdAt, _id, resellerId, ...excluded } = response?.data;
        // setFormData(excluded);
        // setSelectedCustomer(excluded?.userDetails);
        setInvoiceData(response?.data);
        if (response?.data?.paid === response?.data?.total) {
          setIsPaid(true);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getInvoiceDetails();
  }, []);

  return (
    <div className="mx-auto max-w-3xl bg-gray-100 p-6">
      <div className="rounded-lg bg-white p-6 shadow-md">
        <div className="mb-6 flex items-center justify-between">
          <div className="flex items-center">
            <FaArrowLeft
              onClick={() => {
                navigate("/admin/invoice");
              }}
              className="mr-2 h-5 w-5 cursor-pointer"
            />
            <h2 className="text-xl font-semibold">
              Record Payment for Invoice # {searchParams.get("id")}
            </h2>
            {/* <span className="ml-2 rounded bg-purple-100 px-2 py-1 text-xs text-purple-700">
              Partially
            </span> */}
          </div>
          {/* <div>
            <button className="mr-2 rounded border border-gray-300 px-3 py-1">
              Cancel
            </button>
            <button className="rounded border border-gray-300 bg-brand-main px-3 py-1 text-white">
              Show Invoice
            </button>
          </div> */}
        </div>

        <form onSubmit={handleSubmit} className="grid grid-cols-2 gap-6">
          {isPaid ? (
            <PaymentSuccess
              price={formData.amount}
              name={invoiceData?.userDetails?.fullName}
            />
          ) : (
            <div>
              {/* <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Number <span className="text-red-500">*</span>
              </label>
              <input 
                type="text" 
                name="number"
                value={formData.number}
                onChange={handleChange}
                className="w-full p-2 border border-gray-300 rounded"
              />
              {errors.number && <p className="text-red-500 text-xs mt-1">{errors.number}</p>}
            </div> */}
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  Date <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  {moment(new Date()).format("DD/MM/YYYY")}
                </div>
                {errors.date && (
                  <p className="mt-1 text-xs text-red-500">{errors.date}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  Amount <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <span className="absolute left-2 top-2">₹</span>
                  <input
                    type="number"
                    step="0.01"
                    name="amount"
                    value={formData.amount}
                    onChange={handleChange}
                    className="w-full rounded border border-gray-300 p-2 pl-6"
                  />
                </div>
                {errors.amount && (
                  <p className="mt-1 text-xs text-red-500">{errors.amount}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  Payment Mode <span className="text-red-500">*</span>
                </label>
                <div className="relative">
                  <select
                    name="paymentMode"
                    value={formData.paymentMode}
                    onChange={handleChange}
                    className="w-full appearance-none rounded border border-gray-300 p-2"
                  >
                    <option value="">Select</option>
                    <option value="cash">Cash</option>
                    <option value="card">Card</option>
                    <option value="bank_transfer">Bank Transfer</option>
                    <option value="upi">UPI</option>
                  </select>
                  <FaChevronDown className="absolute right-2 top-2 h-5 w-5 text-gray-400" />
                </div>
                {errors.paymentMode && (
                  <p className="mt-1 text-xs text-red-500">
                    {errors.paymentMode}
                  </p>
                )}
              </div>

              <div className="mb-4">
                <label className="mb-1 block text-sm font-medium text-gray-700">
                  Description
                </label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="w-full rounded border border-gray-300 p-2"
                  rows="3"
                ></textarea>
              </div>
              <button
                type="submit"
                className="rounded bg-brand-main px-4 py-1 text-white"
              >
                {isLoading ? "Please Wait" : "Record payment"}
              </button>
            </div>
          )}

          {invoiceData && (
            <div>
              <div className="space-y-6 rounded bg-gray-50 p-4">
                <h3 className="mb-2 font-semibold">
                  Client : {invoiceData?.userDetails?.fullName}
                </h3>
                <p className="mb-1 text-sm text-gray-900">
                  Email : {invoiceData?.userDetails?.email}
                </p>
                <p className="mb-1 text-sm text-gray-900">
                  Phone : {invoiceData?.userDetails?.Phone}
                </p>
                <hr />
                {/* <p className="text-sm text-gray-900 mb-1">
                Payment Status: <span className="px-2 py-1 text-xs bg-purple-100 text-purple-700 rounded">Partially</span>
              </p> */}
                <p className="mb-1 text-sm text-gray-900">
                  Sub Total: ₹ {invoiceData?.subTotal}
                </p>
                <p className="mb-1 text-sm text-gray-900">
                  Total: ₹ {invoiceData?.total}
                </p>
                <p className="mb-1 text-sm text-gray-900">
                  Extra: ₹ {invoiceData?.extra}
                </p>
                <p className="text-sm text-red-900">
                  Remaining: ₹ {invoiceData?.total - invoiceData?.paid}
                </p>
                <p className="text-sm text-green-900">
                  Paid: ₹ {invoiceData?.paid}
                </p>
               
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default PaymentForm;
