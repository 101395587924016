import React, { useState, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  SET_GROUP_PROPERTIES,
} from "../../../../../redux/reducerSlice/arSlice";

import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

function GroupEditorSection({ whatsClicked }) {
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);

  // const anchorFilter = useSelector((state) => state.ar.data.anchor).filter(
  //   (data) => data?.id !== whatsClicked?.userData?.id
  // );
  // const anchor = anchorFilter?.length ? anchorFilter : null;//add filter so not include self

  const anchor = useSelector((state) => state?.ar?.data?.anchor)?.filter(
    (data) => data?.id !== whatsClicked?.userData?.id
    );
    
    const selectedGroup = ardata?.anchor?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );
    const { id, linkedAnchor  } = selectedGroup || {};


  const [animationDelay, setAnimationDelay] = useState(
    ardata?.anchor?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.delay
  );

  const [animationDuration, setAnimationDuration] = useState(
    ardata?.anchor?.find((data) => data?.id === whatsClicked?.userData?.id)
      ?.animation?.duration
  );

  useEffect(() => {
    setAnimationDelay(
      ardata?.anchor?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.delay
    );

    setAnimationDuration(
      ardata?.anchor?.find((data) => data?.id === whatsClicked?.userData?.id)
        ?.animation?.duration
    );
  }, [whatsClicked?.userData?.id]);

  const anchorDataId = {
    id: ardata?.anchor?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    ),
  };

  const isAnimationanchor = useSelector((state) => {
    const icon = state?.ar?.data?.anchor?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );

    return icon?.isAnimation;
  });

  const handleAnchorChange = (event) => {
    dispatch(SET_GROUP_PROPERTIES({ key: "LinkTo", value: event.target.value, id }));
  };

 
  return (
    <div>
      {whatsClicked?.userData?.type === "anchor" && anchor.length!==0 && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
              <p className="py-1">Attach </p>
              {/* icons Group Edit Bar  */}
              {whatsClicked?.userData?.type === "anchor" && (
              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Parent Group</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={linkedAnchor || ""}
                      onChange={handleAnchorChange}
                    >
                      <option value="">None</option>
                      {anchor.map((anchor) => (
                      <option key={anchor.id} value={anchor.id}>
                        {anchor.name }
                      </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          
      )}

      {whatsClicked?.userData?.type === "anchor" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p className="py-1">Animation</p>

            {/* Group Animation checkbox */}
            {whatsClicked?.userData?.type === "anchor" && (
              <div className="border-t-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnimationanchor}
                      onChange={() => {
                        const action = {
                          key: "isAnimation",
                          value: !isAnimationanchor,
                          id: anchorDataId.id.id,
                        };
                        dispatch(SET_GROUP_PROPERTIES(action));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Apply</span>}
                />
              </div>
            )}

            {/* Group Animation  */}
            {whatsClicked?.userData?.type === "anchor" &&
              isAnimationanchor && (
                <div className=" my-1 flex flex-col gap-4 bg-white text-sm">
                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Trigger</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={
                        ardata?.anchor?.find(
                          (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation.trigger || ""
                      }
                      onChange={(event) => {
                        const action = {
                          parentKey: "Animation",
                          key: "trigger",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_GROUP_PROPERTIES(action));
                      }}
                    >
                      <option value="Autoplay">Autoplay</option>
                      <option value="Click">Click</option>
                    </select>
                  </div>
                  <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Duration (ms)</label>
                    <input
                      id="duration"
                      type="number"
                      className="w-full border p-1"
                      value={animationDuration}
                      onChange={(event) =>
                        setAnimationDuration(event.target.valueAsNumber)
                      }
                      onBlur={() => {
                        const action = {
                          parentKey: "Animation",
                          key: "duration",
                          value: animationDuration,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_GROUP_PROPERTIES(action));
                      }}
                    />
                  </div>
                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Delay (ms)</label>
                    <input
                      id="delay"
                      type="number"
                      className="w-full border p-1"
                      value={animationDelay}
                      onChange={(event) =>
                        setAnimationDelay(event.target.valueAsNumber)
                      }
                      onBlur={() => {
                        const action = {
                          parentKey: "Animation",
                          key: "delay",
                          value: animationDelay,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_GROUP_PROPERTIES(action));
                      }}
                    />
                  </div>

                  <div className="flex flex-col justify-center">
                    <label className="text-gray-900">Direction</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={
                        ardata?.anchor?.find(
                          (data) => data?.id === whatsClicked?.userData?.id
                        )?.animation.direction || ""
                      }
                      onChange={(event) => {
                        const action = {
                          parentKey: "Animation",
                          key: "direction",
                          value: event.target.value,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_GROUP_PROPERTIES(action));
                      }}
                    >
                      <option value="topToBottom">Top to Bottom</option>
                      <option value="leftToRight">Left to Right</option>
                      <option value="rightToLeft">Right to Left</option>
                      <option value="bottomToTop">Bottom to Top</option>
                    </select>
                  </div>
                </div>
              )}
          </div>
        </div>
      )}
    </div>
  );
}

export default GroupEditorSection;
