import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, FormControlLabel } from "@mui/material";

import { SET_MODEL_PROPERTIES } from "../../../../../redux/reducerSlice/arSlice";

export default function ModalEditorSection({ whatsClicked }) {
  
  const dispatch = useDispatch();
  const ardata = useSelector((state) => state.ar.data);

  const anchor = useSelector((state) => state.ar.data?.anchor) || null;
    
    const selectedModel = ardata?.model?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );
    const { id, linkedAnchor  } = selectedModel || {};

  const [animationDelay, setAnimationDelay] = useState(0);
  const [animationDuration, setAnimationDuration] = useState(0);

  useEffect(() => {
    const modelData = ardata?.model?.find(
      (data) => data?.id === whatsClicked?.userData?.id
    );
    if (modelData) {
      setAnimationDelay(modelData?.animation?.delay || 0);
      setAnimationDuration(modelData?.animation?.duration || 0);
    }
  }, [ardata, whatsClicked?.userData?.id]);

  const isAnimationModel = useSelector((state) => {
    const model = state?.ar?.data?.model?.find(
      (data) => data.id === whatsClicked?.userData?.id
    );
    return model?.isAnimation;
  });

   const handleAnchorChange = (event) => {
        dispatch(SET_MODEL_PROPERTIES({ key: "LinkTo", value: event.target.value, id }));
    };

  return (
    <div>
      {whatsClicked?.userData?.type === "Model3d" && (
        <div className="mt-2 px-2"></div>
      )}

      {whatsClicked?.userData?.type === "Model3d" && anchor && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
              <p className="py-1">Attach </p>

              {/* Model3d Group Edit Bar  */}
              {whatsClicked?.userData?.type === "Model3d"  && (
              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Parent Group</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={linkedAnchor || ""}
                      onChange={handleAnchorChange}
                    >
                      <option value="">None</option>
                      {anchor?.map((anchor) => (
                      <option key={anchor.id} value={anchor.id}>
                        {anchor.name }
                      </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
          
        )}

      {whatsClicked?.userData?.type === "Model3d" && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
            <p className="py-1">Animation</p>

            {/* CheckBox for auto-rotation enable    */}
            {whatsClicked?.userData?.type === "Model3d" && (
              <div className="border-t-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      // checked={isAutorotate}
                      onChange={(e) => {
                        const action = {
                          key: "isAutorotate",
                          value: e.target.checked,
                          id: whatsClicked?.userData?.id,
                        };
                        // console.log("isAutorotateModel", isAutorotateModel);
                        dispatch(SET_MODEL_PROPERTIES(action));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Auto-rotate</span>}
                />
              </div>
            )}

            {/* CheckBox for Video animation enable    */}
            {whatsClicked?.userData?.type === "Model3d" &&(
              <div className="border-t-2">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isAnimationModel}
                      onChange={() => {
                        const action = {
                          key: "isAnimation",
                          value: !isAnimationModel,
                          id: whatsClicked?.userData?.id,
                        };
                        dispatch(SET_MODEL_PROPERTIES(action));
                      }}
                    />
                  }
                  label={<span style={{ fontSize: 14 }}>Apply</span>}
                />
              </div>
            )}

            {/* Animation Editor Bar */}
            {whatsClicked?.userData?.type === "Model3d" && isAnimationModel && (
              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                <div className="flex flex-col justify-center">
                  <label className="text-gray-900">Trigger</label>
                  <select
                    name=""
                    id=""
                    className="w-full border p-1"
                    value={
                      ardata?.model?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                      )?.animation.trigger || ""
                    }
                    onChange={(event) => {
                      const action = {
                        parentKey: "Animation",
                        key: "trigger",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_MODEL_PROPERTIES(action));
                    }}
                  >
                    <option value="Autoplay">Autoplay</option>
                    <option value="Click">Click</option>
                  </select>
                </div>
                <div className="flex flex-col justify-center ">
                  <label className="text-gray-900">Duration (ms)</label>
                  <input
                    id="duration"
                    type="number"
                    className="w-full border p-1"
                    value={animationDuration}
                    onChange={(event) => {
                      setAnimationDuration(event.target.valueAsNumber);
                    }}
                    onBlur={() => {
                      const action = {
                        parentKey: "Animation",
                        key: "duration",
                        value: animationDuration,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_MODEL_PROPERTIES(action));
                    }}
                  />
                </div>
                <div className="flex flex-col justify-center ">
                  <label className="text-gray-900">Delay (ms)</label>
                  <input
                    id="delay"
                    type="number"
                    className="w-full border p-1"
                    value={animationDelay}
                    onChange={(event) => {
                      setAnimationDelay(event.target.valueAsNumber);
                    }}
                    onBlur={() => {
                      const action = {
                        parentKey: "Animation",
                        key: "delay",
                        value: animationDelay,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_MODEL_PROPERTIES(action));
                    }}
                  />
                </div>
                <div className="flex flex-col justify-center gap-2 ">
                  <label className="text-gray-900">Direction</label>
                  <select
                    name=""
                    id=""
                    value={
                      ardata?.model?.find(
                        (data) => data?.id === whatsClicked?.userData?.id
                      )?.animation.direction || ""
                    }
                    className="p-1"
                    onChange={(event) => {
                      const action = {
                        parentKey: "Animation",
                        key: "direction",
                        value: event.target.value,
                        id: whatsClicked?.userData?.id,
                      };
                      dispatch(SET_MODEL_PROPERTIES(action));
                    }}
                  >
                    <option value="topToBottom">Top to Bottom</option>
                    <option value="leftToRight">Left to Right</option>
                    <option value="rightToLeft">Right to Left</option>
                    <option value="bottomToTop">Bottom to Top</option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
