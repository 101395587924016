import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import { MdDeleteForever } from "react-icons/md";
import toast from "react-hot-toast";
import { instance } from "common/Instance";
import { loadTexture } from "../EditorUtils";
import imageCompression from "browser-image-compression";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import {
  ADD_ITEM_TO_CAROUSEL,
  REMOVE_CAROUSEL_CHILDREN,
  UPDATE_CAROUSEL_FOR_EDITOR,
} from "../../../../../redux/reducerSlice/arSlice";

function CarouselEditorSection({ whatsClicked, meshArray }) {
  const dispatch = useDispatch();
  const arData = useSelector((state) => state.ar.data);

  const groupData = arData.carousel?.find(
    (element) => element?.id === whatsClicked?.userData?.id
  );

  const anchor = useSelector((state) => state.ar.data.anchor) || null;
    
  const selectedCarousel = arData?.carousel?.find(
    (data) => data?.id === whatsClicked?.userData?.id
  );
  const { id, linkedAnchor  } = selectedCarousel || {};

  const userToken = useSelector((state) => state.auth.auth.token);
  const user = useSelector((state) => state.auth.auth.user);

  const [loading, setLoading] = useState(false);

  const handleFileUpload = async (e) => {
    try {
      setLoading(true);
      const files = e.target.files;

      if (files && files.length > 0) {
        const uploadFile = files[0];
        const allowedTypes = [
          "image/jpeg",
          "image/png",
          "image/jpg",
          "video/mp4",
        ];

        if (!allowedTypes.includes(uploadFile.type)) {
          setLoading(false);
          toast.error("Please select a .jpg, .jpeg,.png or .mp4 file");
          return;
        }

        const fileSizeInMB = uploadFile.size / (1024 * 1024);

        if(uploadFile.type==='video/mp4' && fileSizeInMB > 25){
          setLoading(false);
          toast.error("File size should be less than 25 MB");
          return;
        }

       
        if (fileSizeInMB > 5 && uploadFile.type!=='video/mp4' ) {
          setLoading(false);
          toast.error("File size should be less than 5 MB");
          return;
        }

        let  formData = new FormData();
        const options = {
          maxSizeMB: 1,
          maxWidthOrHeight: 1920,
          useWebWorker: true,
        };

        try {
          if (uploadFile.type === "video/mp4") {
           
            console.log(files, "files");
            formData.append("file", files[0]);
            formData.append("assetType", "video");
          } else {
             
            const compressedBlob = await imageCompression(uploadFile, options);
            console.log("Compressed file:", compressedBlob);
            console.log(
              `Compressed file size: ${compressedBlob.size / 1024 / 1024} MB`
            );

            // Convert Blob to File
            const compressedFile = new File([compressedBlob], uploadFile.name, {
              type: uploadFile.type,
              lastModified: Date.now(),
            });

            formData.append("file", compressedFile);

            formData.append("assetType", "image");
          }

          const response = await instance.post(
            `/api/ar/upload/${user.username}/${arData.name}`,
            formData,
            {
              headers: {
                authorization: `Bearer ${userToken}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          if (response.status === 200) {
            if (uploadFile.type === "video/mp4") {
              toast.success("Video is uploaded successfully");
              setLoading(false);
            } else {
              toast.success("Image is uploaded successfully");
              setLoading(false);
            }


         


            if (uploadFile.type === "video/mp4") {
           

              let data = {
                id: groupData.id,
                childId: uuidv4(),
                type: "carousel",
                type1: "regular",
                type2: "video",
                src: response.data.url,
                position: { x: 0, y: 0.0005, z: 0 },
                rotation: { x: -Math.PI / 2, y: 0, z: 0 },
                scale: { x:0.8, y: 0.8, z: 0.8 },
                geometry: "Plane",
                urlLink: "",
                isAnimation: false,
                animation: {
                  duration: 0,
                  delay: 0,
                  direction: "topToBottom",
                },
              };

              dispatch(ADD_ITEM_TO_CAROUSEL(data));
            } else {
              const texture = await loadTexture(response.data.url);
        
              const textureWidth = texture.image.width;
              const textureHeight = texture.image.height;
              const aspectRatio = textureWidth / textureHeight;
              const baseSize = 0.8;

              let data = {
                id: groupData.id,
                childId: uuidv4(),
                type: "carousel",
                type1: "regular",
                type2: "photo",
                src: response.data.url,
                position: { x: 0, y: 0.0005, z: 0 },
                rotation: { x: -Math.PI / 2, y: 0, z: 0 },
                scale: { x: aspectRatio * baseSize, y: baseSize, z: baseSize },
                geometry: "Plane",
                urlLink: "",
                isAnimation: false,
                animation: {
                  duration: 0,
                  delay: 0,
                  direction: "topToBottom",
                },
              };

              dispatch(ADD_ITEM_TO_CAROUSEL(data));
            }
          } else {
            throw new Error(`Unexpected response status: ${response.status}`);
          }
        } catch (compressionError) {
          console.error("Compression error:", compressionError);
          toast.error("Image compression failed");
        }
      }
    } catch (error) {
      console.error("File upload error:", error);
      toast.error("Image is not uploaded");
    } finally {
      setLoading(false);
    }
  };

  const handleAnchorChange = (event) => {
    dispatch(UPDATE_CAROUSEL_FOR_EDITOR({ LinkTo:event.target.value, id }));
  };

  console.log(groupData, "groupData");

  return (
    <div>
      {whatsClicked?.userData?.type === "Group" && (
        <>
          <div className="mt-2 items-center px-2">
            <div className="rounded-md bg-white px-4 py-2 shadow-md">
              <p className="p-1">Slides</p>
              <ul className="flex  w-full flex-col items-center justify-between gap-2">
                {groupData?.children
                  ?.filter((element) => element?.type1 !== "dumyImage")
                  ?.map((element, index) => (
                    <li
                      className="flex w-full items-center  justify-between rounded-md bg-gray-200 p-2 "
                      key={index}
                    >

                      {
                        element?.type2==="video" ?<video src={element?.src} width="30" height="30" disabled></video>
                        : <img
                        src={element?.src}
                        alt="element"
                        className="w-[50px]  object-cover  "
                      />

                      }
                     
                      <div
                        className="cursor-pointer"
                        onClick={() => {
                          const action = {
                            childId: element.childId,
                            id: element.id,
                          };
                          dispatch(REMOVE_CAROUSEL_CHILDREN(action));
                        }}
                      >
                        <MdDeleteForever color="red" size={26} />
                      </div>
                    </li>
                  ))}
              </ul>

              <div className="mx-4 my-4 flex justify-end">
                <label
                  htmlFor="fileInput"
                  className="flex  items-center justify-center rounded-md    px-2 pb-2 text-white  dark:!border-navy-700 lg:pb-0"
                >
                  <input
                    type="file"
                    id="fileInput1"
                    accept="image/png, image/jpeg, image/jpg, video/mp4"
                    className="hidden"
                    onChange={(e) => {
                      handleFileUpload(e);
                    }}
                  />

                  <button
                    type="button"
                    onClick={() =>
                      document.getElementById("fileInput1").click()
                    }
                    className="rounded-md bg-[#3d2fa9] px-4 py-2 text-white"
                  >
                    Add slide
                  </button>
                </label>
              </div>
            </div>
          </div>
        </>
      )}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <div className="flex flex-col items-center justify-center gap-4">
          <h1 className="font-bold">Uploading Slide</h1>
          <CircularProgress color="inherit" />
        </div>
      </Backdrop>

      {whatsClicked?.userData?.type === "Group" && anchor && (
        <div className="mt-2 px-2">
          <div className="rounded-md bg-white px-4 py-2 shadow-md">
              <p className="py-1">Attach </p>

              {/* Model3d Group Edit Bar  */}
              {whatsClicked?.userData?.type === "Group" && (
              <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
                <div className="flex flex-col justify-center ">
                    <label className="text-gray-900">Parent Group</label>
                    <select
                      name=""
                      id=""
                      className="w-full border p-1"
                      value={linkedAnchor || ""}
                      onChange={handleAnchorChange}
                    >
                      <option value="">None</option>
                      {anchor?.map((anchor) => (
                      <option key={anchor.id} value={anchor.id}>
                        {anchor.name }
                      </option>
                      ))}
                    </select>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

      
    </div>
  );
}

export default CarouselEditorSection;

const Model = () => {};
// {whatsClicked?.userData?.type === "Group" && (
//   <div className="mt-2 px-2">
//     <div className="rounded-md bg-white px-4 py-2 shadow-md">
//       <p className="py-1">Animation</p>

//       {/* CheckBox for Video animation enable    */}
//       {whatsClicked?.userData?.type === "Group" && (
//         <div className="border-t-2">
//           <FormControlLabel
//             control={
//               <Checkbox
//                 checked={isAnimationModel}
//                 onChange={() => {
//                   const action = {
//                     key: "isAnimation",
//                     value: !isAnimationModel,
//                     id: whatsClicked?.userData?.id,
//                   };
//                   dispatch(UPDATE_CAROUSEL_FOR_EDITOR(action));
//                 }}
//               />
//             }
//             label={<span style={{ fontSize: 14 }}>Apply</span>}
//           />
//         </div>
//       )}

//       {/* Animation Editor Bar */}
//       {whatsClicked?.userData?.type === "Group" && isAnimationModel && (
//         <div className="mx-2 my-1 flex flex-col gap-4 bg-white text-sm ">
//           <div className="flex flex-col justify-center">
//             <label className="text-gray-900">Trigger</label>
//             <select
//               name=""
//               id=""
//               className="w-full border p-1"
//               value={
//                 arData?.carousel?.find(
//                   (data) => data?.id === whatsClicked?.userData?.id
//                 )?.animation.trigger || ""
//               }
//               onChange={(event) => {
//                 const action = {
//                   parentKey: "Animation",
//                   key: "trigger",
//                   value: event.target.value,
//                   id: whatsClicked?.userData?.id,
//                 };
//                 dispatch(UPDATE_CAROUSEL_FOR_EDITOR(action));
//               }}
//             >
//               <option value="Autoplay">Autoplay</option>
//               <option value="Click">Click</option>
//             </select>
//           </div>
//           <div className="flex flex-col justify-center ">
//             <label className="text-gray-900">Duration (ms)</label>
//             <input
//               id="duration"
//               type="number"
//               className="w-full border p-1"
//               value={animationDuration}
//               onChange={(event) => {
//                 setAnimationDuration(event.target.valueAsNumber);
//               }}
//               onBlur={() => {
//                 const action = {
//                   parentKey: "Animation",
//                   key: "duration",
//                   value: animationDuration,
//                   id: whatsClicked?.userData?.id,
//                 };
//                 dispatch(UPDATE_CAROUSEL_FOR_EDITOR(action));
//               }}
//             />
//           </div>
//           <div className="flex flex-col justify-center ">
//             <label className="text-gray-900">Delay (ms)</label>
//             <input
//               id="delay"
//               type="number"
//               className="w-full border p-1"
//               value={animationDelay}
//               onChange={(event) => {
//                 setAnimationDelay(event.target.valueAsNumber);
//               }}
//               onBlur={() => {
//                 const action = {
//                   parentKey: "Animation",
//                   key: "delay",
//                   value: animationDelay,
//                   id: whatsClicked?.userData?.id,
//                 };
//                 dispatch(UPDATE_CAROUSEL_FOR_EDITOR(action));
//               }}
//             />
//           </div>
//           <div className="flex flex-col justify-center gap-2 ">
//             <label className="text-gray-900">Direction</label>
//             <select
//               name=""
//               id=""
//               value={
//                 ardata?.carousel?.find(
//                   (data) => data?.id === whatsClicked?.userData?.id
//                 )?.animation.direction || ""
//               }
//               className="p-1"
//               onChange={(event) => {
//                 const action = {
//                   parentKey: "Animation",
//                   key: "direction",
//                   value: event.target.value,
//                   id: whatsClicked?.userData?.id,
//                 };
//                 dispatch(UPDATE_CAROUSEL_FOR_EDITOR(action));
//               }}
//             >
//               <option value="topToBottom">Top to Bottom</option>
//               <option value="leftToRight">Left to Right</option>
//               <option value="rightToLeft">Right to Left</option>
//               <option value="bottomToTop">Bottom to Top</option>
//             </select>
//           </div>
//         </div>
//       )}
//     </div>
//   </div>
// )}